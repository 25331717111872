exports.aliases = {
    'uoregon': 'oregon'
};

exports.customSignups = {
    umaine: {
        mainText: 'Make new friends and find your UMaine classmates!',
        secondaryText: 'Sign up now with your preapproved personal or UMaine email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:umaine@nearpeer.net'} data-focus-outline='radius:4,padding:5'>umaine@nearpeer.net</a></p>
        </div>
    },
    emcc: {
        mainText: 'Make new friends and connect with your EMCC classmates!',
        secondaryText: 'Sign up now with your EMCC.edu email:'
    },
    csun: {
        mainText: 'Make new friends and connect with your CSUN classmates!',
        secondaryText: 'Sign up now with your preapproved CSUN email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Want to learn more? <a href={'https://www.csun.edu/node/402298'} rel='noopener noreferrer' target='_blank' data-focus-outline='radius:4,padding:5'>Visit CSUN&apos;s information page about Nearpeer</a></p>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:CSUN@nearpeer.net'} data-focus-outline='radius:4,padding:5'>CSUN@nearpeer.net</a></p>
        </div>
    },
    asga: {
        mainText: 'Nearpeer builds student connection, community, and belonging on campus. Use it for the ASGA National Summit to meet fellow summit attendees and get a feel for how it can work on your campus.',
        secondaryText: 'Sign up now with email used for event registration:',
        useWideLogo: true,
        useExtraWideLogo: true,
        footer: <div style={{ textAlign: 'center', color: 'white' }}>
            <p>Want to learn more? <a href={'https://info.nearpeer.com/asga-summit'} rel='noopener noreferrer' target='_blank' style={{ color: 'white' }} data-focus-outline='radius:4,padding:5'>Visit Nearpeer for SGA Leaders</a></p>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:asga@nearpeer.net'} style={{ color: 'white' }} data-focus-outline='radius:4,padding:5'>asga@nearpeer.net</a></p>
        </div>
    },
    vcuchp: {
        mainText: 'Make new friends and find your VCU College of Health Professions classmates!',
        secondaryText: 'Sign up now with your preapproved personal or VCU email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:vcuchp@nearpeer.net'} data-focus-outline='radius:4,padding:5'>vcuchp@nearpeer.net</a></p>
        </div>
    },
    fullerton: {
        mainText: 'Make new friends and find your Cal State Fullerton classmates!',
        secondaryText: 'Sign up now with your preapproved personal or Cal State Fullerton email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:csufullerton@nearpeer.net'} data-focus-outline='radius:4,padding:5'>csufullerton@nearpeer.net</a></p>
        </div>
    },
    stanislausstate: {
        mainText: 'Make new friends and find your Stanislaus State classmates!',
        secondaryText: 'Sign up now with your preapproved personal or Stanislaus State email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:stanislausstate@nearpeer.net'} data-focus-outline='radius:4,padding:5'>stanislausstate@nearpeer.net</a></p>
        </div>
    },
    butler: {
        mainText: 'Make new friends and find your Butler University community!',
        secondaryText: 'Sign up with the email address you used to apply to Butler on the Common App:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:butler@nearpeer.net'} data-focus-outline='radius:4,padding:5'>butler@nearpeer.net</a></p>
        </div>
    },
    lli: {
        mainText: 'Make new friends and find your LLI in Chesterfield classmates!',
        secondaryText: 'Sign up now with your preapproved personal email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:llichesterfield@nearpeer.net'} data-focus-outline='radius:4,padding:5'>llichesterfield@nearpeer.net</a></p>
        </div>
    },
    valdosta: {
        mainText: 'Make new friends and find your Valdosta State University classmates!',
        secondaryText: 'Sign up now with your preapproved personal or VSU email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:valdosta@nearpeer.net'} data-focus-outline='radius:4,padding:5'>valdosta@nearpeer.net</a></p>
        </div>
    },
    fcc: {
        mainText: 'Make new friends and find your Fresno City College classmates!',
        secondaryText: 'Sign up now with your preapproved personal or Fresno City College email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:fcc@nearpeer.net'} data-focus-outline='radius:4,padding:5'>fcc@nearpeer.net</a></p>
        </div>
    },
    csusb: {
        mainText: 'Make new friends and find your CSU San Bernardino classmates!',
        secondaryText: 'Sign up with your personal email used by csusb or @‌coyote.csusb.edu email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:csusb@nearpeer.net'} data-focus-outline='radius:4,padding:5'>csusb@nearpeer.net</a></p>
        </div>
    },
    csulb: {
        mainText: 'Make new friends and find your CSU Long Beach classmates!',
        secondaryText: 'Sign up now with your preapproved CSU Long Beach email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:csulb@nearpeer.net'} data-focus-outline='radius:4,padding:5'>csulb@nearpeer.net</a></p>
        </div>
    },
    oregon: {
        mainText: 'Make new friends and find your University of Oregon community!',
        secondaryText: 'Sign up now with your preapproved personal or UO email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:uoregon@nearpeer.net'} data-focus-outline='radius:4,padding:5'>uoregon@nearpeer.net</a></p>
        </div>
    },
    depauw: {
        mainText: 'Make new friends and find your DePauw community!',
        secondaryText: 'Sign up now with your preapproved DePauw University email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:depauw@nearpeer.net'} data-focus-outline='radius:4,padding:5'>depauw@nearpeer.net</a></p>
        </div>
    },
    stthomas: {
        mainText: 'Make new friends and find your University of St. Thomas community!',
        secondaryText: 'Sign up now with your preapproved University of St. Thomas email address:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:stthomas@nearpeer.net'} data-focus-outline='radius:4,padding:5'>stthomas@nearpeer.net</a></p>
        </div>
    },
    umass: {
        mainText: 'Make new friends and find your UMass Amherst community!',
        useWhiteLanding: true,
        useWideLogo: true,
        logoSidePadding: 40,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:umass@‌nearpeer.net'} data-focus-outline='radius:4,padding:5'>umass@‌nearpeer.net</a></p>
        </div>
    },
    suffolk: {
        mainText: 'Make new friends and find your Suffolk community!',
        secondaryText: 'Sign up with the email you used to submit your application to Suffolk University:',
        useWhiteLanding: true,
        useWideLogo: true,
        logoSidePadding: 40,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:suffolk@‌nearpeer.net'} data-focus-outline='radius:4,padding:5'>suffolk@‌nearpeer.net</a></p>
        </div>
    },
    baylor: {
        mainText: 'Make new friends and find your Baylor community!',
        secondaryText: 'Sign up with your pre-approved @‌baylor.edu email:',
        useWhiteLanding: true,
        useWideLogo: true,
        logoSidePadding: 110,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:baylor@‌nearpeer.net'} data-focus-outline='radius:4,padding:5'>baylor‌@nearpeer.net</a></p>
        </div>
    },
    utah: {
        mainText: 'Make new friends and find your Utah community!',
        secondaryText: 'Sign up with your pre-approved personal or @‌utah.edu email:',
        useWhiteLanding: true,
        useWideLogo: true,
        logoSidePadding: 40,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:‌utah@‌nearpeer.net'} data-focus-outline='radius:4,padding:5'>‌utah@nearpeer.net</a></p>
        </div>
    }
};

exports.customTeamSignups = {
    chp: {
        mainText: 'Make new friends and find your people at VCU College of Health Professions',
        secondaryText: 'Sign up now with your preapproved VCU employee email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:chp@nearpeer.net'} data-focus-outline='radius:4,padding:5'>chp@nearpeer.net</a></p>
        </div>
    },
    bonterra: {
        mainText: 'Make new friends and find your people at Bonterra!',
        secondaryText: 'Sign up now with your preapproved Bonterra email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:bonterra@nearpeer.net'} data-focus-outline='radius:4,padding:5'>bonterra@nearpeer.net</a></p>
        </div>
    },
    teamnu: {
        mainText: 'Make new friends and find your people at National University!',
        secondaryText: 'Sign up now with your preapproved National University employee email:',
        useWhiteLanding: true,
        useWideLogo: true,
        footer: <div>
            <p>Need Nearpeer helpdesk support? Email <a href={'mailto:teamnu@nearpeer.net'} data-focus-outline='radius:4,padding:5'>teamnu@nearpeer.net</a></p>
        </div>
    }
};

exports.allCustomSignups = {
    ...exports.customSignups,
    ...exports.customTeamSignups
};
